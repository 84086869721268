
import './App.css';
import Signup from "./components/auth/Signup"
import Login from "./components/auth/Login"
import Reset from "./components/auth/Reset"
import DashboardLayout from "./components/dashboard/DashboardLayout"
import Statistics from "./components/dashboard/pages/Statistics"
import CreateRider from "./components/dashboard/pages/CreateRider"
import { BrowserRouter as Router, Route, Routes,Navigate } from "react-router-dom";
import CreateAsset from './components/dashboard/pages/CreateAsset';
import AssignAsset from './components/dashboard/pages/AssignAsset';
import { ToastProvider } from 'react-toast-notifications';
import RiderPayments from './components/dashboard/pages/ RiderPayments';
import JaliApplicants from './components/dashboard/pages/JaliApplicants';
import UserProfile from './components/dashboard/pages/UserProfile';
import moment from 'moment';
import PaymentsReceipts from './components/dashboard/pages/PaymentsReceipts';
import {useSelector } from "react-redux"
import { checkloggedInStatus, getUser} from './redux/transactions/UntappedSlice';
function App() {
  const user_info = useSelector(getUser);
  const accessTokenExpiry = user_info?moment(user_info.access_token_expiry).format('YYYY-MM-DD h:mm:ss a'):'';
  const currentTime = moment().format('YYYY-MM-DD h:mm:ss a');
  const isLoggedIn = user_info === null ? false : accessTokenExpiry > currentTime;
  return (
    <ToastProvider>
      <div>
        <Router>
          <div className=" justify-center  ">
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route path="/reset" element={<Reset />} />
              <Route path="/sign-up" element={<Signup />} />

              <Route
                path="/dashboard-home/*"
                element={isLoggedIn ? <DashboardLayout /> : <Navigate to="/" />}
              />
              <Route
                path="/statistics"
                element={isLoggedIn ? <Statistics /> : <Navigate to="/" />}
              />
              <Route
                path="/create-asset"
                element={isLoggedIn ? <CreateAsset /> : <Navigate to="/" />}
              />
              <Route
                path="/create-rider"
                element={isLoggedIn ? <CreateRider /> : <Navigate to="/" />}
              />

              <Route
                path="/assign-asset"
                element={isLoggedIn ? <AssignAsset /> : <Navigate to="/" />}
              />
              <Route
                path="/rider-payments"
                element={isLoggedIn ? <RiderPayments /> : <Navigate to="/" />}
              />
              <Route
                path="/payments-receipts"
                element={
                  isLoggedIn ? <PaymentsReceipts /> : <Navigate to="/" />
                }
              />
              <Route
                path="/user-profile"
                element={isLoggedIn ? <UserProfile /> : <Navigate to="/" />}
              />

              <Route
                path="/jali-dashboard"
                element={isLoggedIn ? <JaliApplicants /> : <Navigate to="/jali-dashboard" />}
              />
            </Routes>
          </div>
        </Router>
      </div>
    </ToastProvider>
  );
}

export default App;
