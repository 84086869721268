import React, { useState, useEffect, useCallback } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import DashboardLayout from "../DashboardLayout";
import AddressSelectComponent from "./AddressSelectComponent";
import PopModal from "./PopModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsersViewfinder,
  faDownload,
  faSearch,
  faPenToSquare,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { Pagination, Select } from "antd";
import useFetchPdf from "../../../api/pdf";
import axios from "axios";
import { debounce } from "lodash";

function JaliApplicants() {
  const [searchQuery, setsearchQuery] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [idValidations, setIdValidations] = useState(false);
  const [phoneValidations, setPhoneValidations] = useState(false);
  const [id, setId] = useState("");
  const [selectedApplicantId, setSelectedApplicantId] = useState(null);
  const [statuses, setStatuses] = useState({}); // Track statuses for each applicant
  const [isEditMode, setIsEditMode] = useState(false);
  const [editFormData, setEditFormData] = useState({});

  const { data: pdfData, isLoading: isPdfLoading } =
    useFetchPdf(selectedApplicantId);

  const handleDownloadPdf = (applicantId) => {
    setSelectedApplicantId(applicantId);
  };

  useEffect(() => {
    if (pdfData) {
      // Logic to download the PDF file
      const url = URL.createObjectURL(pdfData);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${selectedApplicantId}.pdf`;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
    }
  }, [pdfData, selectedApplicantId]);

  const formatYYYYMMDD = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${year}-${month}-${day}`;
  };

  const DEPLOYED_URL = "https://applicationbackend.jalikoi.rw/api/v1";
  const [viewRider, setViewRider] = useState(false);
  const [viewRiderInfo, setViewRiderInfo] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const Moridebtn_name = "Jali applicants";
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleEditRider = (applicant) => {
    setViewRiderInfo(applicant);
    setEditFormData(applicant);
    setIsEditMode(true);
    setViewRider(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prev) => {
      const newData = { ...prev };
      const keys = name.split(".");
      let current = newData;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!current[keys[i]]) current[keys[i]] = {};
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = value;
      return newData;
    });
  };

  const updateApplicantMutation = useMutation({
    mutationFn: (updatedData) =>
      axios.patch(
        `${DEPLOYED_URL}/application/${updatedData.id}`,
        updatedData
      ),
    onSuccess: () => {
      console.log("Edit successifully");
    },
  });

  const ParentComponent = ({ initialVillageId, apiUrl, handleInputChange }) => {
    const [editFormData, setEditFormData] = useState({
      insurer_one: {
        residence: {
          village_id: initialVillageId || "",
        },
      },
    });
  };
  const handleResidenceSelect = (selectedVillageId) => {
    setEditFormData((prevData) => ({
      ...prevData,
      insurer_one: {
        ...prevData.insurer_one,
        residence: {
          ...prevData.insurer_one.residence,
          village_id: selectedVillageId,
        },
      },
    }));
  };


  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateApplicantMutation.mutateAsync(editFormData);
      setIsEditMode(false);
      setViewRider(false);
    } catch (error) {
      console.error("Failed to update applicant", error);
    }
  };

  const updateLoanDecision = async (id, decision) => {
    const { data } = await axios.patch(
      `${DEPLOYED_URL}/application/${id}/decision`,
      {
        decision,
      }
    );
    return data;
  };

  const useUpdateLoanDecision = () => {
    return useMutation({
      mutationFn: ({ id, decision }) => updateLoanDecision(id, decision),
      onSuccess: () => {},
    });
  };

  const updateDecisionMutation = useUpdateLoanDecision();

  const handleDecisionChange = (applicantId, newDecision) => {
    updateDecisionMutation.mutate(
      { id: applicantId, decision: newDecision },
      {
        onSuccess: () => {
          setStatuses((prevStatuses) => ({
            ...prevStatuses,
            [applicantId]: newDecision,
          }));
        },
        onError: (error) => {
          console.error("Failed to update decision", error);
        },
      }
    );
  };
  const handleViewChildEvent = () => {
    setViewRider(!viewRider);
  };

  const handleViewRider = (applicant) => {
    setViewRiderInfo(applicant);
    setViewRider(true);
  };

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["applicants", searchQuery, currentPage],
    queryFn: async () => {
      const response = await fetch(
        `${DEPLOYED_URL}/application/search?query=${encodeURIComponent(
          searchQuery
        )}&page=${currentPage}&per_page=${itemsPerPage}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const applicants = await response.json();

      const filesPromises = applicants.map(async (applicant) => {
        try {
          const filesResponse = await fetch(
            `${DEPLOYED_URL}/application/files/${applicant.id}`
          );
          if (!filesResponse.ok && filesResponse.status === 404) {
            return;
          }
          return filesResponse.json();
        } catch {
          return [];
        }
      });

      const filesArray = await Promise.all(filesPromises);

      return applicants.map((applicant, index) => ({
        ...applicant,
        files: filesArray[index],
      }));
    },
    refetchOnWindowFocus: true,
    staleTime: 1000 * 60 * 5,
    keepPreviousData: true,
  });

  const handleSearch = (e) => {
    e.preventDefault();
    setsearchQuery(e.target.value);
    setCurrentPage(1);
    refetch();
  };

  const paginatedData = data?.slice(startIndex, endIndex);

  if (isLoading) {
    return (
      <DashboardLayout>
        <div className="mb-4 flex align-center">
          <input
            placeholder="Search by First name or Last name"
            value={searchQuery}
            onChange={handleSearch}
            className="w-full max-w-md"
          />
        </div>
        <div className="flex justify-center align-baseline">Loading...</div>
      </DashboardLayout>
    );
  }

  if (isError) {
    return (
      <DashboardLayout>
        <div className="mb-4">
          <input
            placeholder="Search by First name or Last name"
            value={searchQuery}
            onChange={handleSearch}
            className="w-full max-w-md"
          />
        </div>
        <div>Error loading data.</div>
      </DashboardLayout>
    );
  }
  if (data) {
    return (
      <>
        <DashboardLayout>
          <div className="mb-4">
            <input
              placeholder="Search by First name or Last name"
              value={searchQuery}
              onChange={handleSearch}
              className="w-full max-w-md"
            />
          </div>
          <table className="w-full text-left text-base text-gray-700 dark:text-gray-900 border">
            <thead className="text-xs text-gray-700 uppercase dark:text-gray-400 border-b">
              <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-black">
                <th scope="col" className="p-2">
                  First Name
                </th>
                <th scope="col" className="p-2">
                  Last Name
                </th>
                <th scope="col" className="p-2">
                  DOB
                </th>
                <th scope="col" className="p-2">
                  NID
                </th>
                <th scope="col" className="p-2">
                  Phone
                </th>
                <th scope="col" className="p-2">
                  Created at (YYYY-MM-DD)
                </th>
                <th scope="col" className="p-2">
                  Actions
                </th>
                <th scope="col" className="p-2">
                  Download applicant details
                </th>
                <th scope="col" className="p-2">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((applicant) => {
                const createdDate = new Date(applicant.created_at);
                const isNew =
                  new Date().getTime() - createdDate.getTime() <
                  24 * 60 * 60 * 1000;
                const applicantStatus =
                  statuses[applicant.id] || (isNew ? "New" : "");
                return (
                  <tr
                    key={applicant.id}
                    className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-black"
                  >
                    <td className="px-2 py-3">{applicant.first_name}</td>
                    <td className="px-2 py-3">{applicant.last_name}</td>
                    <td className="px-2 py-3">
                      {new Date(applicant.dob).toLocaleDateString()}
                    </td>
                    <td className="px-2 py-3">{applicant.nid}</td>
                    <td className="px-2 py-3">{applicant.phone}</td>
                    <td className="px-2 py-3">
                      {formatYYYYMMDD(applicant.created_at)}
                    </td>
                    <td className="px-2 py-3 flex justify-between">
                      <div className="flex justify-between align-center">
                        <div>
                          <FontAwesomeIcon
                            icon={faEye}
                            size="1x"
                            className="cursor-pointer text-gray-900 mx-5"
                            onClick={() => handleViewRider(applicant)}
                          />
                        </div>
                        <div>
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            size="1x"
                            className="cursor-pointer text-gray-900 mx-5"
                            onClick={() => handleEditRider(applicant)}
                          />
                        </div>
                        {viewRiderInfo && viewRiderInfo.id === applicant.id && (
                          <PopModal
                            setOpenModal={viewRider}
                            onChildEvent={handleViewChildEvent}
                            Title={
                              isEditMode ? "Edit Applicant" : Moridebtn_name
                            }
                            button={null}
                          >
                            {isEditMode ? (
                              <form
                                onSubmit={handleEditSubmit}
                                className="bg-white rounded-lg shadow-md p-6"
                              >
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                  <h2 className="md:col-span-2 text-xl font-bold">
                                    Main Applicant Information
                                  </h2>
                                  {[
                                    "first_name",
                                    "last_name",
                                    "phone",
                                    "nid",
                                    "status",
                                  ].map((field) => (
                                    <div key={field}>
                                      <label className="block text-sm font-medium text-gray-700 capitalize">
                                        {field.replace("_", " ")}
                                      </label>
                                      <input
                                        type={field === "dob" ? "date" : "text"}
                                        name={field}
                                        value={editFormData[field] || ""}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                      />
                                    </div>
                                  ))}

                                  <h2 className="md:col-span-2 text-xl font-bold mt-6">
                                    First Guarantor (Insurer One)
                                  </h2>
                                  {[
                                    "first_name",
                                    "last_name",
                                    "identity",
                                    "gender",
                                    "occupation",
                                    "phone",
                                  ].map((field) => (
                                    <div key={field}>
                                      <label className="block text-sm font-medium text-gray-700 capitalize">
                                        {field.replace("_", " ")}
                                      </label>
                                      <input
                                        type="text"
                                        name={`insurer_one.${field}`}
                                        value={
                                          editFormData.insurer_one?.[field] ||
                                          ""
                                        }
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                      />
                                    </div>
                                  ))}
                                  <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                      Residence
                                    </label>
                                    <AddressSelectComponent
                                      onAddressSelect={handleResidenceSelect}
                                      label="Residence address"
                                      apiUrl={DEPLOYED_URL}
                                      initialVillageId={
                                        editFormData.insurer_one.residence
                                          .village_id
                                      }
                                    />
                                  </div>
                                  <h2 className="md:col-span-2 text-xl font-bold mt-6">
                                    Second Guarantor (Insurer Two)
                                  </h2>
                                  {[
                                    "first_name",
                                    "last_name",
                                    "identity",
                                    "gender",
                                    "occupation",
                                    "phone",
                                    "status",
                                  ].map((field) => (
                                    <div key={field}>
                                      <label className="block text-sm font-medium text-gray-700 capitalize">
                                        {field.replace("_", " ")}
                                      </label>
                                      <input
                                        type="text"
                                        name={`insurer_two.${field}`}
                                        value={
                                          editFormData.insurer_two?.[field] ||
                                          ""
                                        }
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                      />
                                    </div>
                                  ))}
                                  <div>
                                    <AddressSelectComponent
                                      onAddressSelect={handleResidenceSelect}
                                      label="Residence address"
                                      apiUrl={DEPLOYED_URL}
                                      initialVillageId={
                                        editFormData.insurer_one.residence
                                          .village_id
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="mt-6 flex justify-end">
                                  <button
                                    type="button"
                                    onClick={() => setIsEditMode(false)}
                                    className="mr-2 px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="submit"
                                    className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800"
                                    disabled={updateApplicantMutation.isLoading}
                                  >
                                    {updateApplicantMutation.isLoading
                                      ? "Updating..."
                                      : "Save Changes"}
                                  </button>
                                </div>
                                {updateApplicantMutation.isError && (
                                  <div className="mt-4 text-red-600 text-center">
                                    {
                                      "An error occurred while updating the applicant."}
                                  </div>
                                )}
                              </form>
                            ) : (
                              <div className="bg-white rounded-lg shadow-md p-6">
                                <div className="bg-white rounded-lg shadow-md p-6">
                                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                                    <div>
                                      <h2 className="text-xl font-bold text-gray-900 mb-4">
                                        Applicant Photo
                                      </h2>
                                      {viewRiderInfo.files &&
                                      viewRiderInfo.files.length > 0 ? (
                                        viewRiderInfo.files
                                          .filter((file) => {
                                            const fileName = file
                                              .split("/")
                                              .pop();
                                            const fileType =
                                              fileName.split("-")[3];
                                            return fileType === "PHOTO";
                                          })
                                          .map((file, index) => {
                                            const filePath = file;
                                            return (
                                              <div
                                                key={index}
                                                className="flex flex-col items-center"
                                              >
                                                <img
                                                  className="h-64 w-64 object-cover rounded-lg shadow-md"
                                                  alt={`${viewRiderInfo.first_name} ${viewRiderInfo.last_name}`}
                                                  src={filePath}
                                                />
                                                <p className="mt-2 text-sm text-gray-500">
                                                  {viewRiderInfo.first_name}{" "}
                                                  {viewRiderInfo.last_name}
                                                </p>
                                              </div>
                                            );
                                          })
                                      ) : (
                                        <div className="flex items-center justify-center h-64 bg-gray-100 rounded-lg">
                                          <p className="text-gray-500">
                                            No photo available
                                          </p>
                                        </div>
                                      )}
                                    </div>

                                    <div>
                                      <h2 className="text-xl font-bold text-gray-900 mb-4">
                                        Address
                                      </h2>
                                      <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                                        {[
                                          {
                                            label: "Province",
                                            value:
                                              viewRiderInfo.residence.village
                                                .cells.sector.district.province
                                                .name,
                                          },
                                          {
                                            label: "District",
                                            value:
                                              viewRiderInfo.residence.village
                                                .cells.sector.district.name,
                                          },
                                          {
                                            label: "Sector",
                                            value:
                                              viewRiderInfo.residence.village
                                                .cells.sector.name,
                                          },
                                          {
                                            label: "Cell",
                                            value:
                                              viewRiderInfo.residence.village
                                                .cells.name,
                                          },
                                          {
                                            label: "Village",
                                            value:
                                              viewRiderInfo.residence.village
                                                .name,
                                          },
                                        ].map((item, index) => (
                                          <div key={index} className="flex">
                                            <span className="font-semibold w-24 text-gray-700">
                                              {item.label}:
                                            </span>
                                            <span className="text-gray-600">
                                              {item.value}
                                            </span>
                                          </div>
                                        ))}
                                      </div>
                                    </div>

                                    <div className="md:col-span-2">
                                      <h2 className="text-xl font-bold text-gray-900 mb-4">
                                        Personal Information
                                      </h2>
                                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                        <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                                          {[
                                            {
                                              label: "First Name",
                                              value: viewRiderInfo.first_name,
                                            },
                                            {
                                              label: "Last Name",
                                              value: viewRiderInfo.last_name,
                                            },

                                            {
                                              label: "Date of Birth",
                                              value: new Date(
                                                viewRiderInfo.dob
                                              ).toLocaleDateString(),
                                            },
                                            {
                                              label: "National ID",
                                              value: viewRiderInfo.nid,
                                            },
                                            {
                                              label: "Phone Number",
                                              value: viewRiderInfo.phone,
                                            },
                                            {
                                              label: "Marital Status",
                                              value: viewRiderInfo.status,
                                            },
                                          ].map((item, index) => (
                                            <div key={index} className="flex">
                                              <span className="font-semibold w-32 text-gray-700">
                                                {item.label}:
                                              </span>
                                              <span className="text-gray-600">
                                                {item.value}
                                              </span>
                                            </div>
                                          ))}
                                        </div>
                                        {viewRiderInfo.partner && (
                                          <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                                            <h3 className="font-semibold text-lg text-gray-800 mb-2">
                                              Partner Information
                                            </h3>
                                            {[
                                              {
                                                label: "First Name",
                                                value:
                                                  viewRiderInfo.partner
                                                    .first_name,
                                              },
                                              {
                                                label: "Last Name",
                                                value:
                                                  viewRiderInfo.partner
                                                    .last_name,
                                              },
                                              {
                                                label: "Phone",
                                                value:
                                                  viewRiderInfo.partner.phone,
                                              },
                                              {
                                                label: "Occupation",
                                                value:
                                                  viewRiderInfo.partner
                                                    .occupation,
                                              },
                                            ].map((item, index) => (
                                              <div key={index} className="flex">
                                                <span className="font-semibold w-32 text-gray-700">
                                                  {item.label}:
                                                </span>
                                                <span className="text-gray-600">
                                                  {item.value}
                                                </span>
                                              </div>
                                            ))}
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    <div className="md:col-span-2">
                                      <h2 className="text-xl font-bold text-gray-900 mb-4">
                                        Guarantor Information
                                      </h2>
                                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                        <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                                          <h3 className="font-semibold text-lg text-gray-800 mb-2">
                                            First Guarantor
                                          </h3>
                                          {[
                                            {
                                              label: "First Name",
                                              value:
                                                viewRiderInfo.insurer_one
                                                  .first_name,
                                            },

                                            {
                                              label: "Last Name",
                                              value:
                                                viewRiderInfo.insurer_one
                                                  .last_name,
                                            },

                                            {
                                              label: "Phone",
                                              value:
                                                viewRiderInfo.insurer_one.phone,
                                            },
                                            {
                                              label: "National ID",
                                              value:
                                                viewRiderInfo.insurer_one
                                                  .identity,
                                            },
                                            {
                                              label: "Occupation",
                                              value:
                                                viewRiderInfo.insurer_one
                                                  .occupation,
                                            },
                                            {
                                              label: "Village",
                                              value:
                                                viewRiderInfo.insurer_one
                                                  .residence.village.name,
                                            },
                                            {
                                              label: "Cell",
                                              value:
                                                viewRiderInfo.insurer_one
                                                  .residence.village.cells.name,
                                            },
                                            {
                                              label: "Sector",
                                              value:
                                                viewRiderInfo.insurer_one
                                                  .residence.village.cells
                                                  .sector.name,
                                            },
                                            {
                                              label: "District",
                                              value:
                                                viewRiderInfo.insurer_one
                                                  .residence.village.cells
                                                  .sector.district.name,
                                            },
                                            {
                                              label: "Province",
                                              value:
                                                viewRiderInfo.insurer_one
                                                  .residence.village.cells
                                                  .sector.district.province
                                                  .name,
                                            },
                                          ].map((item, index) => (
                                            <div key={index} className="flex">
                                              <span className="font-semibold w-32 text-gray-700">
                                                {item.label}:
                                              </span>
                                              <span className="text-gray-600">
                                                {item.value}
                                              </span>
                                            </div>
                                          ))}
                                        </div>

                                        {viewRiderInfo.insurer_two && (
                                          <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                                            <h3 className="font-semibold text-lg text-gray-800 mb-2">
                                              Second Guarantor
                                            </h3>
                                            {[
                                              {
                                                label: "First Name",
                                                value:
                                                  viewRiderInfo.insurer_two
                                                    .first_name,
                                              },
                                              {
                                                label: "Last Name",
                                                value:
                                                  viewRiderInfo.insurer_two
                                                    .last_name,
                                              },
                                              {
                                                label: "Phone",
                                                value:
                                                  viewRiderInfo.insurer_two
                                                    .phone,
                                              },
                                              {
                                                label: "National ID",
                                                value:
                                                  viewRiderInfo.insurer_two
                                                    .identity,
                                              },
                                              {
                                                label: "Occupation",
                                                value:
                                                  viewRiderInfo.insurer_two
                                                    .occupation,
                                              },
                                              {
                                                label: "Village",
                                                value:
                                                  viewRiderInfo.insurer_two
                                                    .residence.village.name,
                                              },
                                              {
                                                label: "Cell",
                                                value:
                                                  viewRiderInfo.insurer_two
                                                    .residence.village.cells
                                                    .name,
                                              },
                                              {
                                                label: "Sector",
                                                value:
                                                  viewRiderInfo.insurer_two
                                                    .residence.village.cells
                                                    .sector.name,
                                              },
                                              {
                                                label: "District",
                                                value:
                                                  viewRiderInfo.insurer_two
                                                    .residence.village.cells
                                                    .sector.district.name,
                                              },
                                              {
                                                label: "Province",
                                                value:
                                                  viewRiderInfo.insurer_one
                                                    .residence.village.cells
                                                    .sector.district.province
                                                    .name,
                                              },
                                            ].map((item, index) => (
                                              <div key={index} className="flex">
                                                <span className="font-semibold w-32 text-gray-700">
                                                  {item.label}:
                                                </span>
                                                <span className="text-gray-600">
                                                  {item.value}
                                                </span>
                                              </div>
                                            ))}
                                          </div>
                                        )}
                                      </div>
                                      <div className="mt-9">
                                        <span className="text-lg font-bold text-gray-900 mb-">
                                          Uploaded files
                                        </span>
                                        {viewRiderInfo.files &&
                                        viewRiderInfo.files.length > 0 ? (
                                          <div className="flex flex-wrap gap-4">
                                            {viewRiderInfo.files.map(
                                              (file, index) => {
                                                const fileName = file
                                                  .split("/")
                                                  .pop();
                                                const fileType = fileName
                                                  .split("-")[3]
                                                  .split(".")[0];
                                                return (
                                                  <div
                                                    key={index}
                                                    className="flex flex-col items-center"
                                                  >
                                                    <a
                                                      href={file}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="text-blue-500 underline"
                                                    >
                                                      Open {fileType}
                                                    </a>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        ) : (
                                          <div>No files uploaded.</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </PopModal>
                        )}
                      </div>
                    </td>
                    <td className="px-2 py-3">
                      <FontAwesomeIcon
                        icon={faDownload}
                        size="1x"
                        className="cursor-pointer text-gray-900"
                        onClick={() => handleDownloadPdf(applicant.id)}
                      />
                    </td>
                    <td className="px-2 py-3">
                      <Select
                        defaultValue={applicant.decision || "Select"}
                        style={{ width: 120 }}
                        onChange={(value) =>
                          handleDecisionChange(applicant.id, value)
                        }
                        loading={updateDecisionMutation.isLoading}
                      >
                        <Select.Option value="APPROVED">Approved</Select.Option>
                        <Select.Option value="REJECTED">Rejected</Select.Option>
                        <Select.Option value="PENDING">Pending</Select.Option>
                      </Select>
                      <span
                        className={`px-2 py-1 rounded text-white ${
                          applicantStatus === "APPROVED"
                            ? "bg-green-200 text-green-700 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-800 dark:text-green-200"
                            : applicantStatus === "REJECTED"
                            ? "bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300"
                            : applicantStatus === "PENDING"
                            ? "bg-yellow-100 text-yellow-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300"
                            : isNew
                            ? "bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300"
                            : ""
                        }`}
                      >
                        {applicantStatus}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="flex justify-center mt-4">
            <Pagination
              current={currentPage}
              pageSize={itemsPerPage}
              total={data.length}
              onChange={handlePageChange}
            />
          </div>
        </DashboardLayout>
      </>
    );
  } else if (data.length < 0) {
    return (
      <DashboardLayout>
        <div className="mb-4 flex align-center">
          <input
            placeholder="Search by First name or Last name"
            value={searchQuery}
            onChange={handleSearch}
            className="w-full max-w-md"
          />
        </div>
        <div className="flex justify-center align-baseline">Loading...</div>
      </DashboardLayout>
    );
  }
}

export default JaliApplicants;
