import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

const AddressSelectComponent = ({
  onAddressSelect,
  label,
  apiUrl,
  initialVillageId,
}) => {
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedDistrict, setSelectedDistricts] = useState(null);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);
  const [selectedVillage, setSelectedVillage] = useState(initialVillageId);


  const fetchProvinces = async (apiUrl) => {
    const response = await fetch(`${apiUrl}/provinces`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  };

  const fetchDistricts = async (provinceId, apiUrl) => {
    const response = await fetch(`${apiUrl}/provinces/${provinceId}/districts`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  };

  const fetchSectors = async (districtId, apiUrl) => {
    const response = await fetch(`${apiUrl}/districts/${districtId}/sectors`);
    //console.log(response)
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  };

  const fetchCells = async (sectorId, apiUrl) => {
    const response = await fetch(`${apiUrl}/sectors/${sectorId}/cells`);
    //console.log(response)
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  };

  const fetchVillages = async (cellId, apiUrl) => {
    const response = await fetch(`${apiUrl}/cells/${cellId}/villages`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  };

  const {
    data: provinces,
    error: provinceError,
    isLoading: provinceLoading,
  } = useQuery({
    queryKey: ["provinces"],
    queryFn: () => fetchProvinces(apiUrl),
  });

  const {
    data: districtsData,
    error: districtError,
    isLoading: districtLoading,
  } = useQuery({
    queryKey: ["districts", selectedProvince],
    queryFn: () =>
      selectedProvince
        ? fetchDistricts(selectedProvince, apiUrl)
        : Promise.resolve({ districts: [] }),
    enabled: !!selectedProvince,
  });

  const { data: sectorsData, isLoading: sectorLoading } = useQuery({
    queryKey: ["sectors", selectedDistrict],
    queryFn: () =>
      selectedDistrict
        ? fetchSectors(selectedDistrict, apiUrl)
        : Promise.resolve({ sectors: [] }),
    enabled: !!selectedDistrict,
  });

  const { data: cellsData, isLoading: cellLoading } = useQuery({
    queryKey: ["cells", selectedSector],
    queryFn: () =>
      selectedSector
        ? fetchCells(selectedSector, apiUrl)
        : Promise.resolve({ cells: [] }),
    enabled: !!selectedSector,
  });

  const { data: villagesData, isLoading: villageLoading } = useQuery({
    queryKey: ["villages", selectedCell],
    queryFn: () =>
      selectedCell
        ? fetchVillages(selectedCell, apiUrl)
        : Promise.resolve({ villages: [] }),
    enabled: !!selectedCell,
  });

  const handleProvinceChange = (e) => {
    const selectedId = parseInt(e.target.value, 10);
    setSelectedProvince(selectedId);
    onAddressSelect(selectedId);
  };

  const handleDistrictChange = (e) => {
    const selectedId = parseInt(e.target.value, 10);
    setSelectedDistricts(selectedId);
    onAddressSelect(selectedId);
  };

  const handleSectorChange = (e) => {
    const selectedId = parseInt(e.target.value, 10);
    setSelectedSector(selectedId);
    onAddressSelect(selectedId);
  };

  const handleCellhange = (e) => {
    const selectedId = parseInt(e.target.value, 10);
    setSelectedCell(selectedId);
    onAddressSelect(selectedId);
  };

  const handleVillageChange = (e) => {
    const selectedId = parseInt(e.target.value, 10);
    //setSelectedVillage(selectedId);
    onAddressSelect(selectedId);
  };


  return (
    <div className="">
      <label className="text-gray-700 font-bold mb-2">{label}</label>
      <div className="mb-4">
        <select
          onChange={handleProvinceChange}
          disabled={provinceLoading}
          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        >
          <option value="">Province</option>
          {provinces &&
            provinces.map((province) => (
              <option key={province.id} value={province.id}>
                {province.name}
              </option>
            ))}
        </select>
      </div>
      {selectedProvince && (
        <div className="mb-4">
          <select
            onChange={handleDistrictChange}
            disabled={districtLoading}
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option value="">District</option>
            {districtsData &&
              districtsData.districts.map((district) => (
                <option key={district.id} value={district.id}>
                  {district.name}
                </option>
              ))}
          </select>
        </div>
      )}
      {selectedDistrict && (
        <div className="mb-4">
          <select
            onChange={handleSectorChange}
            disabled={sectorLoading}
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option value="">Sector</option>
            {sectorsData &&
              sectorsData.sectors.map((sector) => (
                <option key={sector.id} value={sector.id}>
                  {sector.name}
                </option>
              ))}
          </select>
        </div>
      )}
      {selectedSector && (
        <div className="mb-4">
          <select
            onChange={handleCellhange}
            disabled={cellLoading}
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option value="">Cell</option>
            {cellsData &&
              cellsData.cells.map((cell) => (
                <option key={cell.id} value={cell.id}>
                  {cell.name}
                </option>
              ))}
          </select>
        </div>
      )}
      {selectedCell && (
        <div className="mb-4">
          <select
            onChange={handleVillageChange}
            disabled={villageLoading}
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option value="">Village</option>
            {villagesData &&
              villagesData.villages.map((village) => (
                <option key={village.id} value={village.id}>
                  {village.name}
                </option>
              ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default AddressSelectComponent;
