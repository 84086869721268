import { useQuery, useMutation } from "@tanstack/react-query";

const useFetchPdf = (applicantId) => {
  return useQuery({
    queryKey: ["applicant", applicantId],
    queryFn: async () => {
      const response = await fetch(
        `https://applicationbackend.jalikoi.rw/api/v1/pdf/applicants/${applicantId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/pdf',
          },
        }
      );
      console.log(response)
      return await response.blob();
    },
    enabled: !!applicantId,
    refetchOnWindowFocus: false,
  });
};

export default useFetchPdf;
