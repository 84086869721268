import { useEffect, useState } from "react";
import Filter from "../filter";
import Navbar from "../navBar/Navbar";
import Sidebar from "../sideBar/SiderBar";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../redux/transactions/UntappedSlice";
import DashboardApi from "../../api/DashboardApi";

function DashboardHome(props) {
  const user_info = useSelector(getUser);
  const [sidebarOpen, setsidebarOpen] = useState(false);
  const [loggedInuser, setloggedInuser] = useState('');


  const fetchLoggedInUser = async () => {
    const response = await DashboardApi.get('/accounts/edit/', {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${user_info.access}`
      },

    } ).catch((error) => {
      console.log(error)
    })
    setloggedInuser(response.data)
  }

  useEffect(() => {
    fetchLoggedInUser();
  }, [])
  const openSidebar = () => {
    setsidebarOpen(true);
  };
  const closeSidebar = () => {
    setsidebarOpen(false);
  };


  return (
    <div className="flex">
      <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
      <div className="flex   flex-col w-full items-center bg-slate-100"  >
        <Navbar sidebarOpen={sidebarOpen} openSidebar={openSidebar}  logInuser={loggedInuser} />
        <div className=" filter-container my-5 p-8"><Filter/></div>
        <div className="page-content w-full justify-center items-center p-4 bg-slate-100 h-full ">{props.children}</div>
      </div>
    </div>
  );
}

export default DashboardHome

